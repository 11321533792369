

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.preloader {
    width: 100%;
    height: 100%;
    background: #000;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.content {
    width: 100%;
    height: 80vh;
    background: -webkit-linear-gradient(#17afeb, #9EA183);
}

.bubbles {
    width: 80%;
    height: 80%;
    overflow: hidden;
}

.bubble {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(50, 103, 217, 0.25);
    will-change: transform;
    transition: 2s;
    justify-content: center;
    align-items: center;
    display: flex;
    
    z-index: 999;
}

    .bubble.selected {
        transform: scale(2);
        background: #ffbf80;
    }

    .bubble-container {
        position: absolute;
        bottom: 0;
        animation: bubbleRise 10s infinite ease-in;
        will-change: transform;
        width:0%    ;
        height:0%;

    }
    .heart-container {
        position: absolute;
        
        animation: heartRise 10s ease-in;
        will-change: transform;
    }
/* Disable text selection with support on major browsers */
.paragraph {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
@keyframes bubbleRise {
    0% {
        opacity: 0;
        bottom: 0;
    }

    15% {
        bottom: 0;
        opacity: 0.1;
        margin-left: 0px;
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
        margin-left: 50px;
    }

    100% {
        bottom: 100%;
        opacity: 0;
    }
}


@keyframes heartRise {
    0% {
        opacity: 0;
    }

    15% {
        opacity: 0.1;
        margin-left: 0px;
        
    }

    25% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
        margin-left: 50px;
    }

    100% {
        bottom: 100%;
        opacity: 0;
        
    }
}

@keyframes centerIt {
    0% {
        
    }

    to {
        left: 50%;
        top: 50%;
    }

}

.spinner-2 {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    margin: 50px auto;
    display: inline-block;
    border: 3px solid transparent;
    border-top-color: orange;
    animation: spinall 2s linear infinite;
}

    .spinner-2:after, .spinner-2:before {
        content: "";
        display: block;
        width: 85px;
        height: 85px;
        border-radius: 50%;
    }

.spinner4:after {
    position: absolute;
    top: 3%;
    left: 3.5%;
    border: 3px solid transparent;
    border-top-color: orange;
    animation: spinall 3s linear infinite;
}

.spinner4:before {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 10%;
    left: 10%;
    border: 3px solid transparent;
    border-top-color: orange;
    animation: spinall 2s linear infinite;
}

@keyframes spinall {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



/*like button - from https://codepen.io/cssninjaStudio/pen/GQoGgG/ */
* {
    box-sizing: border-box;
}

html, body {
    width: 100%;
    height: 100%;
    background: #ededed;
}

.container {
    width: 100%;
    height: auto;
    margin: 0 auto;
    text-align: center;
    padding-top: 5%;
    max-width:100%;
}

    .container h1 {
        font-family: "Lato";
        font-size: 3rem;
        font-weight: 300;
    }

    .container h4 {
        font-family: "Lato";
        font-size: 1.2rem;
        padding-top: 40px;
        color: #999;
    }

    .container .love {
        font-size: 0.9rem;
        color: #ff4f8f;
    }

    .container .wrapper {
    }

        .container .wrapper .like-button {
            position: relative;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: #ccc;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            text-decoration: none;
            overflow: hidden;
        }

            .container .wrapper .like-button .like-overlay {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: #ff4f8f;
                transform: scale(0);
                transition: all 0.4s;
                z-index: 0;
            }

            .container .wrapper .like-button i.not-liked {
                display: block;
                color: #fff;
                position: relative;
                z-index: 1;
            }

            .container .wrapper .like-button i.is-liked {
                display: none;
                color: #fff;
                position: relative;
                z-index: 1;
            }

            .container .wrapper .like-button.is-active .like-overlay {
                transform: scale(1);
            }

            .container .wrapper .like-button.is-active i.not-liked {
                display: none;
            }

            .container .wrapper .like-button.is-active i.is-liked {
                display: block;
            }

@-webkit-keyframes bouncy {
    from, to {
        -webkit-transform: scale(1, 1);
    }

    25% {
        -webkit-transform: scale(0.9, 1.1);
    }

    50% {
        -webkit-transform: scale(1.1, 0.9);
    }

    75% {
        -webkit-transform: scale(0.95, 1.05);
    }
}

@keyframes bouncy {
    from, to {
        transform: scale(1, 1);
    }

    25% {
        transform: scale(0.9, 1.1);
    }

    50% {
        transform: scale(1.1, 0.9);
    }

    75% {
        transform: scale(0.95, 1.05);
    }
}

.bouncy {
    -webkit-animation: bouncy 0.6s;
    animation: bouncy 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.link-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #6dacff;
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s;
}

    .link-button img {
        width: 32px;
        height: 32px;
        display: block;
    }

    .link-button:hover {
        transform: scale(1.1) rotate(180deg);
        background: #ff4f8f;
    }


.overlap {
    position: absolute;
    z-index: -1;
}

.fadeitin {
    opacity:0;
    animation: fadeIn 1s forwards;
    animation-delay:  1s;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.name {
    text-align: center;
}